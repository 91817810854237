<template>
    <div>
    <a target="_blank" href="https://api.whatsapp.com/send?phone=5512991354260&amp;text=Olá%20Moraes%20Guincho,%20Gostaria%20de%20solicitar%20um%20orçamento." class="whatsapp-button">
        <img src="@/assets/whatsapp-32.svg" alt="" width="32" height="32">
    </a>
  </div>
    <br><br><br><br><footer class="footer-section">
        <div class="container">
            <div class="footer-content">
                <div class="row">
                    <div>
                        <h3>Sobre Nós</h3>
                        <p><span>A Moraes Guincho</span> <strong style="color:#ff0000">oferece serviços de guincho e reboque</strong><span>&nbsp;24 horas por dia, 7 dias por semana, com rapidez e eficiência em toda a região. Contamos com uma equipe de profissionais experientes e dedicados, sempre prontos para atender situações de emergência com a máxima segurança e tranquilidade. Confie na Moraes Guincho para todas as suas necessidades de transporte de veículos.</span></p>
                    </div>
                    <div>
                        <h3>Serviços</h3>
                        <ul class="servicos">
                            <li><router-link to="/guincho-para-carros/">Guincho para Carros</router-link></li>
                            <li><router-link to="/guincho-para-motos/">Guincho para Motos</router-link></li>
                            <li><router-link to="/guincho-para-motonetas/">Guincho para Motonetas</router-link></li>
                            <li><router-link to="/guincho-para-maquinas/">Guincho para Máquinas</router-link></li>
                            <li><router-link to="/guincho-para-equipamentos/">Guincho para Equipamentos</router-link></li>
                            <li><router-link to="/guincho-para-quadriciclos/">Guincho para Quadriciclos</router-link></li>
                            <li><router-link to="/guincho-para-triciclos/">Guincho para Triciclos</router-link></li>
                            <li><router-link to="/guincho-para-vans/">Guincho para Vans</router-link></li>
                            <li><router-link to="/guincho-para-peruas/">Guincho para Peruas</router-link></li>
                            <li><router-link to="/guincho-para-bobcats/">Guincho para Bobcats</router-link></li>
                            <li><router-link to="/guincho-para-utilitarios/">Guincho para Utilitários</router-link></li>
                            <li><router-link to="/guincho-para-barcos/">Guincho para Barcos</router-link></li>
                            <li><router-link to="/guincho-para-jet-skis/">Guincho para Jet-skis</router-link></li>
                            <li><router-link to="/guincho-para-lanchas/">Guincho para Lanchas</router-link></li>
                        </ul>
                    </div>
                    <div>
                        <h3>Informações Do Negócio</h3>
                        <div class="contact-info">
                            <a target="_blank" href="https://api.whatsapp.com/send?phone=5512991354260&amp;text=Olá%20Moraes%20Guincho,%20Gostaria%20de%20solicitar%20um%20orçamento." class="icon-text">
        <i class="fab fa-whatsapp"></i>
        <span>(12) 9 9135-4260</span>
    </a>
                            <a target="_blank" href="tel:5512991354260" class="icon-text"><i class="fas fa-phone"></i><span>(12) 9 9135-4260</span></a>
                            <a target="_blank" href="mailto:contato@moraesguincho.com.br" class="icon-text"><i class="far fa-envelope-open"></i><span>contato@moraesguincho.com.br</span></a>
                        </div>
                    </div>
                </div>
            </div><br><br><br>
            <div class="copyright-area">
                <div class="">
                    <ul class="footer-menu">
                        <li><router-link to="/">Home</router-link></li>
                        <!-- <li><router-link to="/termos">Termos</router-link></li>
                        <li><router-link to="/privacidade">Privacidade</router-link></li>
                        <li><router-link to="/politica">Política</router-link></li> -->
                        <li><router-link to="/">Contato</router-link></li>
                    </ul>
                </div>
                <div class="">
                    <p>&copy; {{ year }} - Todos os direitos reservados</p>
                </div>

            </div>
        </div>
    </footer>
</template>

<script>
import '@fortawesome/fontawesome-free/css/all.css'
export default {
  name: 'FooterComponent',
  data () {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>

<style lang="scss" scoped>
.servicos{
    display: flex;
    flex-wrap: wrap;
    li {
    min-width: 45%;
    @media only screen and (max-width: 600px) {
        min-width: 100%;
    }
}

}
a{
    color:#d5d5d5!important
}
i{
    padding: 7px
}
.footer-content{
    padding: 0 5px;
}
.icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 18px;
    margin-right: 5px;
    color: #d5d5d5;
    font-size: 10px;
    text-align: center;
    background-color: #f3f3f6;
    vertical-align: middle;
    border-radius: 50%;
}
.row {
    display: flex;
    flex-wrap: wrap;
    div {
        width:calc(98vw/3)!important;
        @media only screen and (max-width: 600px) {
            min-width: 100vw!important;
}
    }
}
*{
    overflow: hidden!important;
}
h3{
    color:#ff0000
}
.footer-section {
    background: #171717;
    color: #d5d5d5;
    padding: 60px 0 2px 0;
}

ul li {
    margin-bottom: 10px;
    padding-left: 10px;
}

ul li a {
    color: #878787;
    text-decoration: none;
    transition: color 0.3s ease;
}

ul li a:hover {
    color: #ff0000;
}

.contact-info .icon-text {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color:#d5d5d5!important
}

.contact-info .icon-text i {
    margin-right: 10px;
    font-size: 20px;
    color: #ff0000;
}

.subscribe-form input[type="email"] {
    width: 60%;
    padding: 12px;
    border: none;
    outline: none;
    font-size: 14px;
    background-color: #fff;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.subscribe-form button {
    padding: 12px 20px;
    border: none;
    cursor: pointer;
    background-color: #ea8500;
    height: 40.8px;
    margin-left: 2px;
    color: #d5d5d5;
    font-size: 16px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.subscribe-form button:hover {
    background-color: #e64906;
}

.footer-social-icon {
    margin-top: 20px;
}

.footer-social-icon span {
    display: block;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
}

.footer-social-icon a {
    color: #d5d5d5;
    font-size: 20px;
    margin-right: 10px;
}

.footer-social-icon a:hover {
    color: #ff0000;
}

.footer-menu {
    display: flex;
    text-align: center;
    flex-wrap: wrap;
    justify-content: center
}

.footer-menu li {
    margin: 0 10px;
}

.footer-menu li a {
    color: #d5d5d5;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-menu li a:hover {
    color: #ea8500;
}

.copyright-area {
    background-color: #1c1b1b;
    padding: 15px 0;
    color: #d5d5d5;
    text-align: center;
    div{
        margin: 0 auto!important;
    }
}
.copyright{
    display: flex;
    justify-content: center;
}
@media (max-width: 767px) {
    .footer-menu {
        margin-top: 30px;
    }
}
.whatsapp-button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        background-image: radial-gradient(at center center, #25D366 10%, #0dd134 90%);
        width: 48px;
        height: 48px;
        padding: 20px;
        font-size: 16px;
        border-radius: 100%;
        text-decoration: none;
        animation: pulse 2s infinite;
        box-shadow: 0px 0px 21px -6px #25D366;
        // animation: pisca 1.5s infinite;
        transition: background-color 0.3s ease;
        position: fixed;
        bottom: 2vh;
        right: 5vw;
        @media only screen and (max-width: 600px) {
        }
        z-index: 1000;
    }

    .whatsapp-button:hover {
        background-color: #128C7E;
    }

    .whatsapp-button i {
        margin-right: 10px;
        font-size: 20px;
    }
</style>
